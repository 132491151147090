<template>
  <el-form :model="form" :rules="rules" class="form-container" label-width="110px" ref="form">
    <div class="app-container">
      <div class="part">
        <div class="title">还款</div>
        <div
          class="content"
          v-if="haveWaitConfirmFlag === '1'"
        >您的还款还在审核中，需要财务确认后方可再次还款，您可以联系您的专属顾问处理该问题</div>
        <div class="content" v-else>
          <table class="head-table">
            <tbody>
              <tr>
                <td>
                  <el-form-item label="还款金额" prop="repaidAmount">
                    <el-input v-model.number="form.repaidAmount"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <el-form-item label="还款方式" prop="payType">
                    <el-radio-group v-model="form.payType">
                      <el-radio label="weChat">微信支付</el-radio>
                      <el-radio label="alipay">支付宝支付</el-radio>
                      <el-radio label="balance">余额支付</el-radio>
                      <el-radio label="offline">银行转账</el-radio>
                    </el-radio-group>
                    <span>（使用银行转账需上传转账凭证，待财务审核后才还款成功）</span>
                  </el-form-item>
                </td>
              </tr>
              <tr v-if="form.payType === 'offline'">
                <td>
                  <div class="content">
                    <el-row class="head-table img-list">
                      <el-form-item
                        :rules="{required: true, message: '转账凭证不能为空', trigger: 'blur'}"
                        label="上传转账凭证"
                        prop="imageList"
                      >
                        <PictureCard :limit="9" :maxSize="2000 * 1024" v-model="form.imageList"></PictureCard>
                        <div class="tip">大小：最大1M，数量：9张</div>
                      </el-form-item>
                    </el-row>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <el-form-item label="备注" prop="notes">
                    <div class="clearfix" />
                    <el-input :rows="1" placeholder="请输入内容" type="textarea" v-model="form.notes"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <el-button :loading="saveLoading" @click="submitForm('form')" type="primary">确定</el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PayCode
        :postData="postData"
        @changePayType="changePayType"
        @sureRefresh="sureRefresh"
        ref="pay"
      >
        <!-- <template>
          <el-button :disabled="PayCodeDisabled" @click="$refs.pay.show()" type="primary">去支付</el-button>
        </template>-->
      </PayCode>
    </div>
  </el-form>
</template>

<script>
import { saleCreditRepaymentCreate, myRepaymentInfo } from '@/api/sale-credit';
import PictureCard from '@/components/Upload/PictureCard';
import PayCode from '@/components/Pay/PayCode';
import { validateMoney2 } from '@/utils/validate';
export default {
  name: 'my-form',
  props: {
    id: String,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PayCode,
    PictureCard
  },
  computed: {
    createData() {
      const obj = {};
      obj.repaidAmount = this.form.repaidAmount;
      obj.payType = this.form.payType;
      if (this.form.notes) {
        obj.notes = this.form.notes;
      }
      if (this.form.payType === 'offline' && this.form.imageList.length > 0) {
        obj.imageList = this.form.imageList.map(item => {
          return item.url;
        });
      }
      return obj;
    }
  },
  mounted() {
    this.myRepaymentInfo();
  },
  data() {
    const checkRepaidAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('还款金额不能为空或者0'));
      }
      setTimeout(() => {
        if (!validateMoney2(value)) {
          callback('数据有误，请重新输入');
          return;
        } else {
          if (value > this.maxRepaymentAmount) {
            callback(new Error('还款金额不能大于' + this.maxRepaymentAmount + '元'));
          }
          if (value < 0) {
            callback(new Error('还款金额不能小于0'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      postData: {},
      saveLoading: false,
      haveWaitConfirmFlag: '',
      maxRepaymentAmount: '',
      form: {
        // 还款金额
        repaidAmount: '',
        payType: '',
        notes: '',
        imageList: []
      },
      rules: {
        repaidAmount: [{ validator: checkRepaidAmount, trigger: 'blur' }],
        payType: [{ required: true, message: '请选择支付方式', trigger: 'change' }]
      }
    };
  },
  methods: {
    // 切换支付方式
    changePayType(value) {
      this.form.payType = value === '微信' ? 'weChat' : 'alipay';
      this.$nextTick(() => {
        this.$refs.pay.show();
      });
    },
    // 付款完成
    sureRefresh() {
      this.$router.push({ path: '/syoung/shop/detail' });
    },
    myRepaymentInfo() {
      myRepaymentInfo().then(response => {
        if (response.success) {
          this.haveWaitConfirmFlag = response.data.haveWaitConfirm;
          this.maxRepaymentAmount = response.data.maxRepaymentAmount;
          this.form.repaidAmount = response.data.maxRepaymentAmount;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false;
        }
        this.saveLoading = true;
        saleCreditRepaymentCreate(this.createData)
          .then(response => {
            console.log(response);
            if (
              response.success &&
              (this.form.payType === 'weChat' || this.form.payType === 'alipay')
            ) {
              const { payType, bizType, id: tenantTradeNo, repaidAmount: tradeAmount } =
                response.data || {};
              this.postData = {
                payType,
                bizType,
                tenantTradeNo,
                tradeAmount,
                body: '还款',
                subject: '水羊直供'
              };
              this.$nextTick(() => {
                this.$refs.pay.show();
              });

              return;
            }
            if (
              response.success &&
              !(this.form.payType === 'weChat' || this.form.payType === 'alipay')
            ) {
              this.$message.success(response.msg);
              this.$router.push({ path: '/syoung/shop/detail' });
            }
          })
          .finally(() => {
            this.saveLoading = false;
          });
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$text: rgba(0, 0, 0, 0.65);
.el-form-item {
  margin-bottom: 0;
}
.el-select,
.el-input {
  width: 250px;
}
.form-container {
  padding-bottom: 60px;
  position: relative;
  height: 100%;
  overflow: auto;
}
.type-radio .el-radio {
  margin: 3px 6px 3px 0 !important;
}
.fix-part {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: fixed;
  left: 0;
  z-index: 2;
  width: 100%;
  bottom: 0;
  text-align: right;
  .el-form-item {
    padding: 10px 0;
    margin: 0 20px;
  }
}
.part {
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  & > .title {
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    padding: 18px 30px;
    font-size: 16px;
    user-select: none;
  }
  & > .content {
    padding: 18px 30px;
  }
  & ~ .part {
    margin-top: 20px;
  }
}
.head-table {
  width: 100%;
  border-spacing: 0;
  text-align: left;
  tbody tr td {
    width: 33.33%;
    color: $text;
    vertical-align: top;
    .col {
      display: table;
      span {
        display: table-cell;
      }
      span:nth-child(1) {
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.85);
        padding-right: 10px;
      }
    }
  }
  tbody tr ~ tr td {
    padding-top: 14px;
  }
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
}
.avatar {
  width: 100px;
  height: 100px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
}
.avatar {
  width: 100px;
  height: 100px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.my-checked {
  float: left;
  line-height: 35px;
}
.tip {
  color: #666;
  font-size: 12px;
}
</style>
